import { ConfigProviderTheme } from "vant"
import storage from "../../storage"
import { AuthParent, AuthParentImpl } from "../auth"

export interface State {
	theme: ConfigProviderTheme
	parent: AuthParent
	promoteCode?: string
	accessToken?: string
	refreshToken?: string
}

export class StateImpl implements State {
	theme = storage.getTheme() || 'light'
	parent = storage.getParent() || new AuthParentImpl()
	accessToken = storage.getAccessToken()
	refreshToken = storage.getRefreshToken()
}