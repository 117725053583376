
import { Locale } from "vue-i18n"
import storage from "../storage"
import { store } from "../store"

export enum AuthType {
	EMAIL_OTP = 'EMAIL_OTP',
	EMAIL_PASSWORD = 'EMAIL_PASSWORD',
	MOBILE_OTP = 'MOBILE_OTP',
	MOBILE_PASSWORD = 'MOBILE_PASSWORD',
	WECHAT = 'WECHAT'
}

export interface Auth {
	grant_type: string
	locale: string
	zoneId: string
	promoteCode?: string
}

export class AuthImpl implements Auth {
	grant_type: string = 'password'
	locale: string = store.state.parent.locale
	zoneId: string = store.state.parent.zoneId
}

export interface EmailOtpAuth extends Auth {
	email: string
	otp: string
}

export class EmailOtpAuthImpl extends AuthImpl implements EmailOtpAuth {
	email: string = ''
	otp: string = ''
}

export interface EmailPasswordAuth extends Auth {
	email: string
	password: string
}

export class EmailPasswordAuthImpl extends AuthImpl implements EmailPasswordAuth {
	email: string = ''
	password: string = ''
}

export interface MobileOtpAuth extends Auth {
	region?: string
	countryCode?: string
	mobile?: string
	otp?: string
}

export class MobileOtpAuthImpl extends AuthImpl implements MobileOtpAuth {
	region?: string = ''
	countryCode?: string
	mobile?: string
	otp?: string
}

export interface MobilePasswordAuth extends Auth {
	countryCode: string
	mobile: string
	password: string
}

export class MobilePasswordAuthImpl extends AuthImpl implements MobilePasswordAuth {
	countryCode: string = '001'
	mobile: string = ''
	password: string = ''
}

export interface AuthParent {
	id?: string
	nickname?: string
  locale: Locale
  zoneId: string
  complete?: boolean
	locked?: boolean
}

export class AuthParentImpl implements AuthParent {
	locale = storage.getParent()?.locale || navigator.language
	zoneId = storage.getParent()?.zoneId || Intl.DateTimeFormat().resolvedOptions().timeZone
}

export interface Authed {
	access_token: string
	refresh_token: string
	parent: AuthParent
}

export interface RefreshToken {
	grant_type: string
	refresh_token: string
}

export class RefreshTokenImpl implements RefreshToken {
	grant_type: string = 'refresh_token'
	refresh_token: string

	constructor(refreshToken: string) {
		this.refresh_token = refreshToken
	}
}