import { Authed, AuthType, EmailOtpAuth, EmailPasswordAuth, MobileOtpAuth, MobilePasswordAuth, RefreshToken } from "../types/auth";
import service from "./auth-service";
import qs from "qs"

const api = '/oauth/token'

const authAPI = {
    emailOtpAuth (auth: EmailOtpAuth) {
      return service.post<Authed>(api, qs.stringify(auth), { params: { type: AuthType.EMAIL_OTP} })
    },

    emailPasswordAuth (auth: EmailPasswordAuth) {
      return service.post<Authed>(api, qs.stringify(auth), { params: { type: AuthType.EMAIL_PASSWORD} })
    },

    mobileOtpAuth (auth: MobileOtpAuth) {
      return service.post<Authed>(api, qs.stringify(auth), { params: { type: AuthType.MOBILE_OTP} })
    },

    mobilePasswordAuth (auth: MobilePasswordAuth) {
      return service.post<Authed>(api, qs.stringify(auth), { params: { type: AuthType.MOBILE_PASSWORD} })
    },

    refreshToken (refreshToken: RefreshToken) {
      return service.post<string>(api, qs.stringify(refreshToken))
    }
}

export default authAPI